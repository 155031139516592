import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'

type StarProps = {
  trustpilot?: boolean
}

function getIconSize({ trustpilot }: StarProps) {
  return trustpilot ? size(5) : size(4)
}

export const SContainer = styled.div<{ trustpilot?: boolean }>`
  display: flex;
  gap: ${({ trustpilot = false }) => (trustpilot ? '2px' : size(1))};
`

export const SStar = styled.div<StarProps>`
  --icon-size: ${({ trustpilot }) => getIconSize({ trustpilot })};
  height: var(--icon-size);
  width: var(--icon-size);

  svg {
    height: 100%;
    width: 100%;
  }
`
