export const reviewAvatars = [
  'sun',
  'green-dark',
  'white',
  'sun-dark',
  'black',
  'green',
  'sun-light',
  'green-light'
]
