import { TrustpilotStar } from 'components/trustpilot/TrustpilotStar'
import IconStarFill from 'images/icons/IconStarFill.svg'
import IconStarStroke from 'images/icons/IconStarStroke.svg'
import { SContainer, SStar } from './UserReviewRating.styled'

type Props = {
  rating: number
  isTrustpilot: boolean
}

export function UserReviewRating(props: Props) {
  const { rating, isTrustpilot } = props

  const IconFill = isTrustpilot ? (
    <TrustpilotStar rating={rating} />
  ) : (
    <IconStarFill />
  )

  const IconEmpty = isTrustpilot ? (
    <TrustpilotStar rating={0} />
  ) : (
    <IconStarStroke />
  )

  return (
    <SContainer trustpilot={isTrustpilot}>
      {[1, 2, 3, 4, 5].map(value => (
        <SStar key={value} trustpilot={isTrustpilot}>
          {rating >= value ? IconFill : IconEmpty}
        </SStar>
      ))}
    </SContainer>
  )
}
