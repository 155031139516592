import { UserReviewFragment } from 'api/contentful/generated/contentful'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import GoogleBadgeText from 'images/icons/social/IconGoogleWithText.svg'
import TrustpilotBadgeText from 'images/icons/social/IconTrustpilotWithText.svg'
import Image from 'next/image'
import { forwardRef, Ref, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserReviewRating } from '../rating/UserReviewRating'
import {
  SContainer,
  SFooter,
  SHeader,
  SHeaderName,
  SImage,
  SLink,
  SReview,
  SSourceBadge
} from './UserReviewContent.styled'
import { motion, useReducedMotion } from 'framer-motion'
import { css } from '@emotion/react'
import { size } from 'driverama-core/styles/spacing'

type Props = UserReviewFragment & { avatar: string; isCard?: boolean }

export const UserReviewContent = forwardRef(
  (props: Props, ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation(['core'])
    const shouldReduceMotion = useReducedMotion()

    const [open, setOpen] = useState(false)
    const [showMore, setShowMore] = useState(false)

    const textRef = useRef<HTMLSpanElement>(null)

    const { name, rating, review, date, source, avatar } = props
    const isTrustpilot = source === 'Trustpilot'

    useEffect(() => {
      if (!textRef.current) {
        return
      }

      const { scrollHeight, clientHeight } = textRef.current

      if (scrollHeight > clientHeight) {
        setShowMore(true)
      }
    }, [])

    return (
      <SContainer ref={ref} isCard={props.isCard}>
        <SHeader>
          <SImage>
            <Image src={avatar} width={100} height={100} layout="responsive" />
          </SImage>
          <Flex variant="column">
            <SHeaderName size="large">{name}</SHeaderName>
            <UserReviewRating
              rating={rating || 0}
              isTrustpilot={isTrustpilot}
            />
          </Flex>
        </SHeader>

        <Spacer axis="vertical" size={isTrustpilot ? 3 : 4} />

        <motion.div
          variants={{
            open: {
              height: `${textRef.current?.scrollHeight}px`
            },
            closed: {
              height: size(36)
            }
          }}
          initial="closed"
          animate={open ? 'open' : 'closed'}
          transition={{ duration: shouldReduceMotion ? 0 : 0.2 }}
          css={css`
            overflow: hidden;
          `}
        >
          <SReview
            ref={textRef}
            css={css`
              display: -webkit-box;
              line-clamp: ${!open ? 6 : 20};
              -webkit-line-clamp: ${!open ? 6 : 20};
              -webkit-box-orient: vertical;
              overflow: hidden;
            `}
          >
            {review}
          </SReview>
        </motion.div>

        <Spacer axis="vertical" size={1} />

        {open && showMore && (
          <SLink onClick={() => setOpen(false)}>{t('core:reviews_less')}</SLink>
        )}

        {!open && showMore && (
          <SLink onClick={() => setOpen(true)}>{t('core:reviews_more')}</SLink>
        )}

        {!showMore && <Spacer axis="vertical" size={6} />}

        <Spacer axis="vertical" size={4} />

        <SFooter>
          <Flex variant="row" justify="between" align="center">
            <TextBody size="small">
              {t('core:reviews_date', { date: date ? new Date(date) : '' })}
            </TextBody>
            <SSourceBadge>
              {isTrustpilot ? <TrustpilotBadgeText /> : <GoogleBadgeText />}
            </SSourceBadge>
          </Flex>
        </SFooter>
      </SContainer>
    )
  }
)
