import IconStarTrustpilot from 'images/icons/IconStarTrustpilot.svg'

type Props = {
  rating: number
}

const colors = [
  '#DCDCE6',
  '#FF3722',
  '#FF8622',
  '#FFCE00',
  '#73CF11',
  '#00B67A'
]

export function TrustpilotStar({ rating }: Props) {
  return <IconStarTrustpilot color={colors[rating] ?? colors[0]} />
}
