import styled from '@emotion/styled'
import { TextBody, TextBodyMedium } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import {
  color,
  radius,
  shadow,
  weight,
  zIndex
} from 'driverama-core/styles/variables'
import { css } from '@emotion/react'

export const SContainer = styled.div<{ isCard?: boolean }>`
  background-color: ${color('white')};
  border-radius: ${radius('corner')};
  display: flex;
  flex-direction: column;
  min-height: ${size(79)};
  position: relative;
  width: 100%;
  z-index: ${zIndex('order-2')};

  ${({ isCard }) =>
    isCard &&
    css`
      box-shadow: ${shadow(3)};
      padding: ${size(6)};
    `}
`

export const SHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${size(3)};
`

export const SImage = styled.div`
  border-radius: ${radius('full')};
  height: ${size(10)};
  overflow: hidden;
  width: ${size(10)};
`

export const SHeaderName = styled(TextBodyMedium)`
  color: ${color('black')};
`

export const SReview = styled(TextBody)`
  min-height: ${size(36)};
`

export const SLink = styled(TextBody)`
  color: ${color('night-l-100')};
  cursor: pointer;
  font-weight: ${weight('medium')};

  &:any-link {
    color: ${color('night-l-100')};
  }
`

export const SSourceBadge = styled.div`
  height: ${size(6)};
`

export const SFooter = styled.div`
  margin-top: auto;
`
